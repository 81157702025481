<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form ref="form" v-bind:rules="rules" v-bind:model="form" label-position="right" label-suffix=":" size="large" label-width="120px" class="dialog_box">
                <div class="block-head">基本信息</div>
                <el-form-item label="活动名称" prop="name">
                    <el-input type="text" auto-complete="off" v-model="form.name" placeholder="请输入名称" style="margin-bottom:10px;"></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="r">
                    <el-input placeholder="数字越大排名越靠前" v-model="form.r" style="margin-bottom:10px;"></el-input>
                </el-form-item>
                <el-form-item label="位置" prop="position">
                    <el-select v-model="form.position" placeholder="请选择位置" class="handle-select mr10">
                        <el-option v-for="items in positionList" :key="items.index" :label="items.name" :value="items.key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否推荐" prop="is_recommand">
                    <el-radio-group v-model="form.is_recommand">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="展示的样式" prop="style_mode">
                    <el-radio-group v-model="form.style_mode" v-for="(item,index) in styleModeList" :key="index">
                        <el-radio class="style-mode-radio" :label="item.modeValue">{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="样式示例" prop="style_mode">
                    <div class="style-mode-item" style="float: left" v-for="(item,index) in styleModeList" :key="index" @click="selectStyleModeByImg(item.modeValue)" >
                        <el-image :class="{styleModeItemSelect:item.modeValue===form.style_mode}" fit="fill" :src="item.thumb">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                        </el-image>
                    </div>
                </el-form-item>

                <el-form-item label="起止时间" prop="time">
                    <el-date-picker style="margin-bottom:10px;" v-model="form.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="活动开始日期" end-placeholder="活动结束日期" v-on:change="changeDatePicker"></el-date-picker>
                </el-form-item>
                <el-form-item label="专场logo" prop="logo">
                    <el-input v-model="form.logo" placeholder="请输入专场logo链接" style="margin-bottom: 20px;"></el-input>
                    <el-button @click.prevent="choiceImg('logo')">选择图片</el-button>
                    <el-upload class="img1 avatar-uploader" :action="IMAGE_UPLOAD_PRO_URL" v-bind:show-file-list="false" v-bind:on-success="uploadLogoImgSuccess" v-bind:before-upload="beforeUploadLogoImg" :headers="header">
                        <img v-if="form.logo" :src="form.logo" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <p class="tip">尺寸：100*100px</p>
                </el-form-item>
                <el-form-item label="专场展示图片" prop="thumbs">
                    <Imgupload v-model="form.thumbs" :ImgVisible="ImgVisible" @changeImg="changeImg" @changetext="changetext" />
                    <span class="span_gray">建议尺寸800*800像素</span>
                </el-form-item>
                <el-form-item label="专场海报" prop="poster">
                    <el-input v-model="form.poster" placeholder="请输入专场海报链接" style="margin-bottom: 20px;"></el-input>
                    <el-button @click.prevent="choiceImg('poster')">选择图片</el-button>
                    <el-upload class="img3 avatar-uploader img3" :action="IMAGE_UPLOAD_PRO_URL" v-bind:show-file-list="false" v-bind:on-success="uploadPosterImgSuccess" v-bind:before-upload="beforeUploadPosterImg" :headers="header">
                        <img v-if="form.poster" :src="form.poster" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="tip">尺寸：400*400px</span>
                </el-form-item>
                <el-form-item label="专场封面" prop="image">
                    <el-input v-model="form.image" placeholder="请输入专场封面链接" style="margin-bottom: 20px;"></el-input>
                    <el-button @click.prevent="choiceImg('image')">选择图片</el-button>
                    <el-upload class="img4 avatar-uploader" :action="IMAGE_UPLOAD_PRO_URL" v-bind:show-file-list="false" v-bind:on-success="uploadCoverImgSuccess" v-bind:before-upload="beforeUploadCoverImg" :headers="header">
                        <img v-if="form.image" :src="form.image" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="tip">尺寸：750 * 310</span>
                </el-form-item>
                <el-form-item label="专场描述" prop="desc">
                    <el-input style="width: 500px" type="textarea" :rows="4" auto-complete="off" v-model="form.desc" placeholder="请输入专场描述"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm">保 存</el-button>
                    <el-button @click="cancel">取 消</el-button>
                </el-form-item>
                <!-- 选择图片对话框 -->
                <div class="img">
                    <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import { fetchData } from '@/api/index'
import loadEvents from '@/utils/loading'
import Imgupload from '@/components/Imgupload';
import { deal } from '@/common/main';
import bus from '@/common/bus';
import Picture from '@/components/Picture';
import { request } from '@/common/request';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";

const {StyleModeList} = require("@/common/const");
let loads = new loadEvents();
let id = 0;
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Imgupload,
        Picture
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            text: 'image',
            menuList: ['营销', '添加专场'],
            loading: false,
            rules: {
                name: [{
                    required: true,
                    message: '活动名称不能为空',
                    trigger: 'blur'
                }],
                time: [{
                    required: true,
                    message: '活动时间不能为空',
                    trigger: 'blur'
                }],
                logo: [{ validator: deal.checkImage, trigger: ['blur', 'change'] }],
                //thumbs: [{ validator: deal.checkImage, trigger: ['blur', 'change'] }],
                poster: [{ validator: deal.checkImage, trigger: ['blur', 'change'] }],
                image: [{ validator: deal.checkImage, trigger: ['blur', 'change'] }],
                desc: [{
                    required: true,
                    message: '描述不能为空',
                    trigger: 'blur'
                }],
                r: [{
                    required: true,
                    message: '专场排序不能为空',
                    trigger: ['blur', 'change']
                },
                {
                    pattern: '^[0-9]*$',
                    message: "只能输入数字"
                }],
                position: [{
                    required: true,
                    message: '专场位置不能为空',
                    trigger: 'blur'
                }],
                is_recommand: [{
                    required: true,
                    message: '是否推荐未选择',
                    trigger: 'blur'
                }]
            },
            mode: 'add',
            selectGoodsList: [],
            positionList: [],
            platformList: [],
            form: {
                name: '',
                stime: '',
                etime: '',
                logo: '',
                image: '',
                poster: '',
                thumbs: [],
                desc: '',
                is_recommand: 2,
                style_mode:"0",
            },
            header: {},
            styleModeList:StyleModeList,

        };
    },
    created() {
        deal.getconf('special', this)
    },
    methods: {
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;

        },
        choiceImg(text) {
            this.text = text
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            if (this.text === 'many') {
                if (this.form.thumbs.length < 9) {
                    this.form.thumbs.push(url)
                }
                else {
                    this.$message({ type: 'error', message: '最多上传九张图片' })
                }
            } else {
                this.$set(this.form, this.text, url);
            }
        },
        saveData(ret) {
            this.form.goodsList = this.selectGoodsList;
            this.addVisible = false;
        },
        changeImg(val) {
            this.ImgVisible = val
        },
        changetext(val) {
            this.text = val
        },

        uploadLogoImgSuccess: function (res, file, index) {
            if (res.code == 1) {
                loads.close()
                this.form.logo = res.data.uri
            }
        },
        uploadBannerImgSuccess(res, file, index) {
            if (res.code == 1) {
                loads.close()
                this.form.banner = res.data.uri
            }
        },
        uploadPosterImgSuccess(res, file, index) {
            if (res.code == 1) {
                loads.close()
                this.form.poster = res.data.uri
            }
        },
        uploadCoverImgSuccess(res, file, index) {
            if (res.code == 1) {
                loads.close()
                this.form.image = res.data.uri
            }
        },

        //图片上传之前
        beforeUploadLogoImg: function (file) {
            loads.open(`.img1 .el-upload`, '上传中')
            const isJPG = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/bmp' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error('仅支持jpg，png，bmp，gif格式的图片！');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },

        beforeUploadBannerImg: function (file) {
            loads.open(`.img2 .el-upload`, '上传中')
            const isJPG = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/bmp' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error('仅支持jpg，png，bmp，gif格式的图片！');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        beforeUploadPosterImg(file) {
            loads.open(`.img3 .el-upload`, '上传中')
            const isJPG = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/bmp' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error('仅支持jpg，png，bmp，gif格式的图片！');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;

        },
        beforeUploadCoverImg(file) {
            loads.open(`.img4 .el-upload`, '上传中')
            const isJPG = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/bmp' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error('仅支持jpg，png，bmp，gif格式的图片！');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        submitForm() {
            if (this.$refs.form) {
                this.$refs['form'].validate(valid => {
                    if (valid) {
                        var url = '/goods/special/add';
                        //处理时间
                        let param = Object.assign({}, this.form);
                        param.thumbs = this.form.thumbs.join(",");
                        param.stime = deal.timestamp(this.form.time[0]);
                        param.etime = deal.timestamp(this.form.time[1]);
                        request.post(url, param).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.$message.success('添加成功');
                                bus.$emit('close_current_tags', '/activity-PlaceEdit');
                                this.$router.push({ path: '/activity-Session' })
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        return false;
                    }
                });
            }
        },
        // 取消按钮
        cancel() {
            bus.$emit('close_current_tags', '/community-LettresAdd');
            this.$router.push({ path: '/Session' })
        },
        changeDatePicker: function () {

        },
        // 专场通过图片选择展示的样式
        selectStyleModeByImg(modeValue){
            this.form.style_mode=modeValue
        }
    }
};
</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}
/* 选择链接样式 */
.dialog_box .el-button {
    margin-left: 20px;
}

.style-mode-radio{
  margin-right: 20px;
}
.style-mode-item {
  width: 280px;
}
.style-mode-item img{

  float: left;
  overflow: hidden;
}

.styleModeItemSelect{
  border:5px red solid;
  border-radius: 5px;
}

</style>
